// import React, { Component } from "react";
// import { Button } from "@material-ui/core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
// import {
//   GetProductDetails,
//   GetCategoryDetails,
//   GetUserLogin,
//   GetWishListDetails,
// } from "../../../services";
// import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import { addToCart } from "../../../../store/actions/cartActions";
// import { NotificationManager } from "react-notifications";
// import CircularProgress from "@material-ui/core/CircularProgress";

// class Productview extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       list: [],
//       categorybyproduct: [],
//       subcategories: [],
//       isloaded: false,
//       toggle: false,
//       wishlist: [], // State for wishlist items
//       custId: null, // Initialize custId state
//       currentPage: 1,
//       itemsPerPage: 18,
//       visiblePages: 8,
//       pageGroupStart: 1,
//       subcategoryId: null, // Added subcategoryId to state
//       isMobileView: window.innerWidth <= 786,
//     };
//   }

//   async fetchSubCategories() {
//     try {
//       const subcategories =
//         await GetCategoryDetails.getAllCategorySubCategoryList();
//       if (subcategories) {
//         // Remove duplicates and sort the subcategories alphabetically by name
//         const uniqueSubcategories = subcategories.data
//           .filter(
//             (subcategory, index, self) =>
//               index === self.findIndex((s) => s.name === subcategory.name)
//           )
//           .sort((a, b) => a.name.localeCompare(b.name));

//         this.setState({ subcategories: uniqueSubcategories, isloaded: true });
//       } else {
//         NotificationManager.error("Failed to fetch subcategories.");
//       }
//     } catch (error) {
//       console.error("Error fetching subcategories:", error);
//       NotificationManager.error("Error fetching subcategories.");
//     }
//   }

//   async fetchProductsByCategory(categoryId, page = 1) {
//     try {
//       const products = await GetProductDetails.getProductByCategory(categoryId);
//       if (products) {
//         this.setState({
//           list: products.data,
//           isloaded: true,
//           currentPage: page,
//         });
//       } else {
//         NotificationManager.error("No products found for this category.");
//         this.setState({ isloaded: true });
//       }
//     } catch (error) {
//       console.error("Error fetching products by category:", error);
//       NotificationManager.error("Error fetching products by category.");
//       this.setState({ isloaded: true });
//     }
//   }

//   async componentDidMount() {
//     window.scrollTo(0, 0);

//     // Get categoryId, subcategoryId, and page from URL parameters
//     const queryParams = new URLSearchParams(this.props.location.search);
//     const categoryId = queryParams.get("categoryId") || 130;
//     const subcategoryId = queryParams.get("subcategoryId");
//     const page = parseInt(queryParams.get("page"), 10) || 1;

//     await this.fetchSubCategories();

//     if (subcategoryId) {
//       this.setState({ subcategoryId }); // Set subcategoryId in state
//       this.handleFilterCategory({ id: subcategoryId }, page);
//     } else {
//       this.fetchProductsByCategory(categoryId, page);
//     }

//     const email = sessionStorage.getItem("email");
//     if (email) {
//       let user = await GetUserLogin.getCustomerDetail(email);
//       if (user) {
//         this.setState({ custId: user.data.id });
//       }
//     }
//     window.addEventListener("resize", this.handleResize);
//   }

//   componentWillUnmount() {
//     // Remove resize listener when component is unmounted
//     window.removeEventListener("resize", this.handleResize);
//   }

//   handleResize = () => {
//     // Check if the screen width is <= 786px and update the state
//     this.setState({ isMobileView: window.innerWidth <= 786 });
//   };

//   handleAddToCartClick = (product) => {
//     this.props.history.push(`/p/${product.id}`);
//   };

//   handleFilterCategory = async (subcategory, page = 1) => {
//     const subcategoryId = subcategory.id;
//     this.setState({
//       categorybyproduct: [],
//       isloaded: false,
//       toggle: true,
//       currentPage: page,
//       subcategoryId, // Update subcategoryId in state
//     });

//     // Update URL with subcategoryId and page as parameters
//     this.props.history.push(
//       `?subcategoryId=${subcategoryId}&page=${page}`
//     );

//     try {
//       const products = await GetProductDetails.getProductByCategory(subcategoryId);
//       if (products) {
//         this.setState({
//           categorybyproduct: products.data,
//           isloaded: true,
//           // pageGroupStart: 1,
//         });
//         const element = document.getElementById("collapseOne");
//         if (element && element.classList.contains("show")) {
//           element.classList.remove("show");
//         }
//         window.scrollTo(0, 0);
//       } else {
//         NotificationManager.error("Empty data in category", "Undefined");
//       }
//     } catch (error) {
//       NotificationManager.error("Error fetching products by category.");
//       this.setState({ isloaded: true });
//     }
//   };

//   toggleWishlist = (productId) => {
//     const { wishlist } = this.state;
//     const isProductInWishlist = wishlist.includes(productId);

//     if (isProductInWishlist) {
//       this.setState({ wishlist: wishlist.filter((id) => id !== productId) });
//     } else {
//       this.setState({ wishlist: [...wishlist, productId] });
//     }
//   };

//   handleAddToWishlistClick = async (productId) => {
//     const { custId } = this.state;

//     if (!custId) {
//       NotificationManager.error("Please log in to add items to your wishlist.");
//       return;
//     }

//     const data = { custId: custId, productId: productId };

//     try {
//       let result = await GetWishListDetails.addWishlistItem(data); // Call service function
//       if (result) {
//         NotificationManager.success("Added to wishlist!");
//         this.toggleWishlist(productId); // Toggle wishlist after adding
//       } else {
//         NotificationManager.error("Product is already in your wishlist.");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       NotificationManager.error(
//         "An error occurred while adding to the wishlist."
//       );
//     }
//   };

//   handleGoBack = () => {
//     window.history.back(); // Go back to the previous page
//   };

//   handleProductShare = (productId) => {
//     const shareUrl = `https://jc-creations.co.in/p/${productId}`;
//     if (navigator.share) {
//       navigator
//         .share({
//           title: "Check out this product!",
//           url: shareUrl,
//         })
//         .then(() => console.log("Share successful"))
//         .catch((error) => console.log("Sharing failed", error));
//     } else {
//       // Fallback for browsers that do not support the Web Share API
//       console.log("Share this URL:", shareUrl);
//       alert("Copy this URL: " + shareUrl);
//     }
//   };

//   handleShare = (categoryId) => {
//     const shareUrl = `https://jc-creations.co.in/product/catalogsearch/result?categoryId=${categoryId}`;
//     if (navigator.share) {
//       navigator
//         .share({
//           title: "Check out this category!",
//           url: shareUrl,
//         })
//         .then(() => console.log("Share successful"))
//         .catch((error) => console.log("Sharing failed", error));
//     } else {
//       // Fallback for browsers that do not support the Web Share API
//       console.log("Share this URL:", shareUrl);
//       alert("Copy this URL: " + shareUrl);
//     }
//   };

//    // Pagination Methods
//    handlePageChange = (pageNumber) => {
//     this.setState({ currentPage: pageNumber });
//     // Ensure subcategoryId is passed correctly to handleFilterCategory
//     if (this.state.subcategoryId) {
//       this.handleFilterCategory(
//         { id: this.state.subcategoryId },
//         pageNumber
//       );
//     }
//     window.scrollTo(0, 0);
//   };

//   // Handle next page group
//   handleNextPageGroup = () => {
//     const { pageGroupStart, visiblePages, categorybyproduct, itemsPerPage } =
//       this.state;
//     const totalPages = Math.ceil(categorybyproduct.length / itemsPerPage);

//     // Calculate the next group's start index
//     if (pageGroupStart + visiblePages <= totalPages) {
//       this.setState({ pageGroupStart: pageGroupStart + visiblePages });
//       // window.scrollTo(0, 0);
//     }
//   };

//   // Handle previous page group
//   handlePreviousPageGroup = () => {
//     const { pageGroupStart, visiblePages } = this.state;

//     // Calculate the previous group's start index
//     if (pageGroupStart - visiblePages > 0) {
//       this.setState({ pageGroupStart: pageGroupStart - visiblePages });
//       // window.scrollTo(0, 0);
//     }
//   };

//   render() {
//     const {
//       list,
//       subcategories,
//       categorybyproduct,
//       toggle,
//       isloaded,
//       wishlist,
//       currentPage,
//       itemsPerPage,
//       visiblePages,
//       pageGroupStart,
//       isMobileView,
//     } = this.state;

//     // Calculate the number of visible pages based on screen size
//     const maxVisiblePages = visiblePages;

//     // Calculate the index of the first and last product for the current page
//     const indexOfLastProduct = currentPage * itemsPerPage;
//     const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
//     const currentProducts = categorybyproduct.slice(
//       indexOfFirstProduct,
//       indexOfLastProduct
//     ); // Slice the products based on the current page

//     // Calculate total pages
//     const totalPages = Math.ceil(categorybyproduct.length / itemsPerPage);

//     // Determine the page numbers to show
//     const pageNumbers = [];
//     for (
//       let i = pageGroupStart;
//       i < Math.min(pageGroupStart + maxVisiblePages, totalPages + 1);
//       i++
//     ) {
//       pageNumbers.push(i);
//     }

//     return (
//       <div>
//         <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white single-product-header-bk">
//           <div className="container">
//           <div className="row align-items-center justify-content-between">
//           <div className="col-8 col-md-6 d-flex align-items-center">
//                 <a href="#">
//                   <strong>
//                     <span className="mdi mdi-home" /> <a href="/">Home </a>
//                   </strong>
//                 </a>{" "}
//                 <span className="mdi mdi-chevron-right" />{" "}
//                 <a href="#">Categories</a>
//               </div>
//               <div className="col-4 col-md-3 text-md-end d-flex justify-content-end">
//                 <Button
//                 variant="contained"
//                   className="back-btn goback"
//                   onClick={this.handleGoBack}
//                 >
//                   <FontAwesomeIcon
//                     icon={faArrowLeft}
//                     style={{ marginRight: "5px" }}
//                   />{" "}
//                   Back
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </section>

//         <section className="shop-list section-padding">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-3">
//                 <div className="shop-filters">
//                   <div id="accordion">
//                     <div className="card">
//                       <div className="card-header" id="headingOne">
//                         <h5 className="mb-0">
//                           <button
//                             className="btn btn-link"
//                             data-toggle={isMobileView ? "collapse" : ""}
//                             data-target={isMobileView ? "#collapseOne" : ""}
//                             aria-expanded="true"
//                             aria-controls="collapseOne"
//                           >
//                             All Category{" "}
//                             <span className="mdi mdi-chevron-down float-right" />
//                           </button>
//                         </h5>
//                       </div>
//                       <div
//                         id="collapseOne"
//                         className={
//                           isMobileView ? "collapse show" : "show" // Collapse only in mobile view
//                         }
//                         aria-labelledby="headingOne"
//                         data-parent="#accordion"
//                       >
//                         <div className="card-body card-shop-filters">
//                           {isloaded
//                             ? subcategories.map((subcategory, index) => (
//                               <div className="card-body" key={index}>
//                               <div className="list-group">
//                                 <span
//                                   className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
//                                   onClick={() => this.handleFilterCategory(subcategory)}
//                                 >
//                                   {subcategory.name}
//                                   <i
//                                     className="mdi mdi-share"
//                                     onClick={(e) => {
//                                       e.stopPropagation();
//                                       this.handleShare(subcategory.id);
//                                     }}
//                                     style={{
//                                       cursor: "pointer",
//                                     }}
//                                     title="Share this category"
//                                   />
//                                 </span>
//                               </div>
//                             </div>

//                               ))
//                             : ""}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-md-9">
//                 <div className="shop-head">
//                   <h5 className="mb-3">Categories</h5>
//                 </div>
//                 {!isloaded ? (
//                   <div className="progress-bar-bk">
//                     <CircularProgress color="secondary" />
//                     <div>Loading products...</div> {/* Loading message */}
//                   </div>
//                 ) : toggle ? (
//                   <div className="row no-gutters">
//                     {currentProducts.length ? (
//                       currentProducts.map((row, index) => (
//                         <div key={index} className="col-md-4">
//                           <div className="item">
//                             <div
//                               className="product"
//                               style={{ backgroundColor: "black" }}
//                             >
//                               <Link
//                                 to={{
//                                   pathname: `/p/${row.id}`,
//                                   state: row,
//                                 }}
//                               >
//                                 <div className="product-header">
//                                   <img
//                                     loading="lazy" // Lazy load images
//                                     crossOrigin="anonymous"
//                                     className="img-fluid"
//                                     src={row.photo}
//                                     alt={row.name}
//                                     width="100%"
//                                     height="auto"
//                                     style={{ objectFit: "contain" }}
//                                   />
//                                 </div>
//                               </Link>
//                               <div className="product-footer">
//                                 <button
//                                   type="button"
//                                   className="btn btn-secondary btn-sm float-right"
//                                   onClick={() => this.handleProductShare(row.id)}
//                                 >
//                                   <i className="mdi mdi-share" /> Share
//                                 </button>
//                                 <i
//                                   className={`mdi ${
//                                     wishlist.includes(row.id)
//                                       ? "mdi-heart"
//                                       : "mdi-heart-outline"
//                                   } wishlist-icon`}
//                                   onClick={() =>
//                                     this.handleAddToWishlistClick(row.id)
//                                   }
//                                   style={{
//                                     cursor: "pointer",
//                                     marginLeft: "10px",
//                                     color: "gold",
//                                   }} // Set color to gold for both
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       ))
//                     ) : (
//                       <div className="text-danger">
//                         No products found in this category.
//                       </div>
//                     )}
//                   </div>
//                 ) : (
//                   <div className="row no-gutters">
//                     {list.length ? (
//                       list.map((row, index) => (
//                         <div key={index} className="col-md-4">
//                           <div className="item">
//                             <div
//                               className="product"
//                               style={{ backgroundColor: "black" }}
//                             >
//                               <Link
//                                 to={{
//                                   pathname: `/p/${row.id}`,
//                                   state: row,
//                                 }}
//                               >
//                                 <div className="product-header">
//                                   <img
//                                     loading="lazy" // Lazy load images
//                                     crossOrigin="anonymous"
//                                     className="img-fluid"
//                                     src={row.photo}
//                                     alt={row.name}
//                                     width="100%"
//                                     height="auto"
//                                     style={{ objectFit: "contain" }}
//                                   />
//                                 </div>
//                               </Link>
//                               <div className="product-footer">
//                                 <button
//                                   type="button"
//                                   className="btn btn-secondary btn-sm float-right"
//                                   onClick={() => this.handleProductShare(row.id)}
//                                 >
//                                   <i className="mdi mdi-share" /> Share
//                                 </button>
//                                 <i
//                                   className={`mdi ${
//                                     wishlist.includes(row.id)
//                                       ? "mdi-heart"
//                                       : "mdi-heart-outline"
//                                   } wishlist-icon`}
//                                   onClick={() =>
//                                     this.handleAddToWishlistClick(row.id)
//                                   }
//                                   style={{
//                                     cursor: "pointer",
//                                     marginLeft: "10px",
//                                     color: "gold",
//                                   }} // Set color to gold for both
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       ))
//                     ) : (
//                       <div className="text-danger">No products found.</div>
//                     )}
//                   </div>
//                 )}
//                   <div className="pagination-controls">
//             <button
//               className="arrow-button"
//               onClick={this.handlePreviousPageGroup}
//               disabled={pageGroupStart === 1}
//             >
//               &lt;
//             </button>
//             {pageNumbers.map((number) => (
//               <button
//                 key={number}
//                 className={`page-button ${
//                   currentPage === number ? "active" : ""
//                 }`}
//                 onClick={() => this.handlePageChange(number)}
//               >
//                 {number}
//               </button>
//             ))}
//             <button
//               className="arrow-button"
//               onClick={this.handleNextPageGroup}
//               disabled={pageGroupStart + maxVisiblePages > totalPages}
//             >
//               &gt;
//             </button>
//           </div>
//               </div>

//             </div>

//           </div>

//         </section>
//       </div>
//     );
//   }
// }

// const mapDispatchToProps = (dispatch) => ({
//   addToCart: (product) => dispatch(addToCart(product)),
// });

// export default connect(null, mapDispatchToProps)(Productview);

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  GetProductDetails,
  GetCategoryDetails,
  GetUserLogin,
  GetWishListDetails,
} from "../../../services";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../../../store/actions/cartActions";
import { NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import jsPDF from "jspdf";

class Productview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      currentcategoryname: null,
      categorybyproduct: [],
      subcategories: [],
      isloaded: false,
      toggle: false,
      wishlist: [], // State for wishlist items
      custId: null, // Initialize custId state
      currentPage: 1,
      itemsPerPage: 18,
      visiblePages: 8,
      pageGroupStart: 1,
      subcategoryId: null, // Added subcategoryId to state
      isMobileView: window.innerWidth <= 786,
    };
  }

  
  async componentDidMount() {
    window.scrollTo(0, 0);

    // Get categoryId, subcategoryId, and page from URL parameters
    const queryParams = new URLSearchParams(this.props.location.search);
    const categoryId = queryParams.get("categoryId") || 130;
    const subcategoryId = queryParams.get("subcategoryId");
    const page = parseInt(queryParams.get("page"), 10) || 1;

    await this.fetchSubCategories();

    if (subcategoryId) {
      this.setState({ subcategoryId }); // Set subcategoryId in state
      this.handleFilterCategory({ id: subcategoryId }, page);
    } else {
      this.fetchProductsByCategory(categoryId, page);
    }
    this.fetchCategoryName(subcategoryId);
    const email = sessionStorage.getItem("email");
    if (email) {
      let user = await GetUserLogin.getCustomerDetail(email);
      if (user) {
        this.setState({ custId: user.data.id });
      }
    }
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if category or subcategory has changed
    const { categoryId, subcategoryId } = this.state;
    if (prevState.subcategoryId !== subcategoryId || prevState.categoryId !== categoryId) {
      this.fetchCategoryName(subcategoryId || categoryId);  // Update the category name
    }
  }

  componentWillUnmount() {
    // Remove resize listener when component is unmounted
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    // Check if the screen width is <= 786px and update the state
    this.setState({ isMobileView: window.innerWidth <= 786 });
  };

  handleAddToCartClick = (product) => {
    this.props.history.push(`/p/${product.id}`);
  };

  async fetchCategoryName(subcategoryId) {
    const { subcategories } = this.state;
    const category = subcategories.find((cat) => cat.id === subcategoryId);
    if (category) {
      this.setState({ currentcategoryname: category.name });
    }
  }

  async fetchSubCategories() {
    try {
      const subcategories =
        await GetCategoryDetails.getAllCategorySubCategoryList();
      if (subcategories) {
        // Remove duplicates and sort the subcategories alphabetically by name
        const uniqueSubcategories = subcategories.data
          .filter(
            (subcategory, index, self) =>
              index === self.findIndex((s) => s.name === subcategory.name)
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        this.setState({ subcategories: uniqueSubcategories, isloaded: true });
      } else {
        NotificationManager.error("Failed to fetch subcategories.");
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      NotificationManager.error("Error fetching subcategories.");
    }
  }

  async fetchProductsByCategory(categoryId, page = 1) {
    try {
      const products = await GetProductDetails.getProductByCategory(categoryId);
      if (products) {
        this.setState({
          list: products.data,
          isloaded: true,
          currentPage: page,
        });
        await this.fetchCategoryName(categoryId);
      } else {
        NotificationManager.error("No products found for this category.");
        this.setState({ isloaded: true });
      }
    } catch (error) {
      console.error("Error fetching products by category:", error);
      NotificationManager.error("Error fetching products by category.");
      this.setState({ isloaded: true });
    }
  }

  handleFilterCategory = async (subcategory, page = 1) => {
    const subcategoryId = subcategory.id;
    this.setState({
      categorybyproduct: [],
      isloaded: false,
      toggle: true,
      currentPage: page,
      subcategoryId, // Update subcategoryId in state
    });
    await this.fetchCategoryName(subcategoryId);
    // Update URL with subcategoryId and page as parameters
    this.props.history.push(`?subcategoryId=${subcategoryId}&page=${page}`);

    try {
      const products = await GetProductDetails.getProductByCategory(
        subcategoryId
      );
      if (products) {
        this.setState({
          categorybyproduct: products.data,
          isloaded: true,
          // pageGroupStart: 1,
        });
        const element = document.getElementById("collapseOne");
        if (element && element.classList.contains("show")) {
          element.classList.remove("show");
        }
        window.scrollTo(0, 0);
      } else {
        NotificationManager.error("Empty data in category", "Undefined");
      }
    } catch (error) {
      NotificationManager.error("Error fetching products by category.");
      this.setState({ isloaded: true });
    }
  };

  toggleWishlist = (productId) => {
    const { wishlist } = this.state;
    const isProductInWishlist = wishlist.includes(productId);

    if (isProductInWishlist) {
      this.setState({ wishlist: wishlist.filter((id) => id !== productId) });
    } else {
      this.setState({ wishlist: [...wishlist, productId] });
    }
  };

  handleAddToWishlistClick = async (productId) => {
    const { custId } = this.state;

    if (!custId) {
      NotificationManager.error("Please log in to add items to your wishlist.");
      return;
    }

    const data = { custId: custId, productId: productId };

    try {
      let result = await GetWishListDetails.addWishlistItem(data); // Call service function
      if (result) {
        NotificationManager.success("Added to wishlist!");
        this.toggleWishlist(productId); // Toggle wishlist after adding
      } else {
        NotificationManager.error("Product is already in your wishlist.");
      }
    } catch (error) {
      console.error("Error:", error);
      NotificationManager.error(
        "An error occurred while adding to the wishlist."
      );
    }
  };

  handleGoBack = () => {
    window.history.back(); // Go back to the previous page
  };

  handleProductShare = (productId) => {
    const shareUrl = `https://jc-creations.co.in/p/${productId}`;
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this product!",
          url: shareUrl,
        })
        .then(() => console.log("Share successful"))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      console.log("Share this URL:", shareUrl);
      alert("Copy this URL: " + shareUrl);
    }
  };

  handleShare = (categoryId) => {
    const shareUrl = `https://jc-creations.co.in/product/catalogsearch/result?categoryId=${categoryId}`;
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this category!",
          url: shareUrl,
        })
        .then(() => console.log("Share successful"))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      console.log("Share this URL:", shareUrl);
      alert("Copy this URL: " + shareUrl);
    }
  };

  // Pagination Methods
  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
    // Ensure subcategoryId is passed correctly to handleFilterCategory
    if (this.state.subcategoryId) {
      this.handleFilterCategory({ id: this.state.subcategoryId }, pageNumber);
    }
    window.scrollTo(0, 0);
  };

  // Handle next page group
  handleNextPageGroup = () => {
    const { pageGroupStart, visiblePages, categorybyproduct, itemsPerPage } =
      this.state;
    const totalPages = Math.ceil(categorybyproduct.length / itemsPerPage);

    // Calculate the next group's start index
    if (pageGroupStart + visiblePages <= totalPages) {
      this.setState({ pageGroupStart: pageGroupStart + visiblePages });
      window.scrollTo(0, 0);
    }
  };

  // Handle previous page group
  handlePreviousPageGroup = () => {
    const { pageGroupStart, visiblePages } = this.state;

    // Calculate the previous group's start index
    if (pageGroupStart - visiblePages > 0) {
      this.setState({ pageGroupStart: pageGroupStart - visiblePages });
      window.scrollTo(0, 0);
    }
  };

  handleDownloadPDF = async () => {
    const { categorybyproduct, subcategoryId, subcategories } = this.state;

    if (!categorybyproduct || categorybyproduct.length === 0) {
      NotificationManager.error("No products available to download.");
      return;
    }

    // Show notification that download is starting
    NotificationManager.info("Download started...", "Downloading PDF");

    // Fetch the category name using subcategoryId
    const category = subcategories.find((cat) => cat.id === subcategoryId);
    const categoryName = category ? category.name : "Products";

    const pdf = new jsPDF("p", "mm", "a4"); // Portrait mode
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10;
    const imageHeight = (pageHeight - margin * 3) / 2; // Two images per page with equal height
    const imageWidth = pageWidth - margin * 2; // Full page width with margins
    let yPosition = margin;

    const loadImage = (src) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous"; // Allow cross-origin
        img.src = src;

        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
      });

    for (let i = 0; i < categorybyproduct.length; i++) {
      const product = categorybyproduct[i];

      try {
        // Load image
        const img = await loadImage(product.photo);

        // Draw Image
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, img.width, img.height);
        const imgData = canvas.toDataURL("image/png");

        pdf.addImage(
          imgData,
          "PNG",
          margin,
          yPosition,
          imageWidth,
          imageHeight
        );
      } catch (error) {
        console.error(error.message);
        pdf.text("Image failed to load", margin, yPosition + imageHeight / 2);
      }

      // Update yPosition for the next image
      yPosition += imageHeight + margin;

      // If two images are added, start a new page
      if ((i + 1) % 2 === 0 && i + 1 < categorybyproduct.length) {
        pdf.addPage();
        yPosition = margin;
      }
    }

    // Save the PDF with the category name
    const sanitizedFileName = categoryName.replace(/[^a-zA-Z0-9]/g, "_"); // Replace invalid characters
    pdf.save(`${sanitizedFileName}.pdf`);

    // Show notification when download is completed
    NotificationManager.success("Download completed successfully!", "PDF Download");
};


  render() {
    const {
      list,
      subcategories,
      categorybyproduct,
      currentcategoryname,
      toggle,
      isloaded,
      wishlist,
      currentPage,
      itemsPerPage,
      visiblePages,
      pageGroupStart,
      isMobileView,
    } = this.state;

    // Calculate the number of visible pages based on screen size
    const maxVisiblePages = visiblePages;

    // Calculate the index of the first and last product for the current page
    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
    const currentProducts = categorybyproduct.slice(
      indexOfFirstProduct,
      indexOfLastProduct
    ); // Slice the products based on the current page

    // Calculate total pages
    const totalPages = Math.ceil(categorybyproduct.length / itemsPerPage);

    // Determine the page numbers to show
    const pageNumbers = [];
    for (
      let i = pageGroupStart;
      i < Math.min(pageGroupStart + maxVisiblePages, totalPages + 1);
      i++
    ) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white single-product-header-bk">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-8 col-md-6 d-flex align-items-center">
                <a href="#">
                  <strong>
                    <span className="mdi mdi-home" /> <a href="/">Home </a>
                  </strong>
                </a>{" "}
                <span className="mdi mdi-chevron-right" />{" "}
                <a href="#">Categories</a>
              </div>
              <div className="col-4 col-md-3 text-md-end d-flex justify-content-end">
                <Button
                  variant="contained"
                  className="back-btn goback"
                  onClick={this.handleGoBack}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ marginRight: "5px" }}
                  />{" "}
                  Back
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section className="shop-list section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="shop-filters">
                  <div id="accordion">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle={isMobileView ? "collapse" : ""}
                            data-target={isMobileView ? "#collapseOne" : ""}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            All Category{" "}
                            <span className="mdi mdi-chevron-down float-right" />
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        className={
                          isMobileView ? "collapse show" : "show" // Collapse only in mobile view
                        }
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div className="card-body card-shop-filters">
                          {isloaded
                            ? subcategories.map((subcategory, index) => (
                                <div className="card-body" key={index}>
                                  <div className="list-group">
                                    <span
                                      className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                                      onClick={() =>
                                        this.handleFilterCategory(subcategory)
                                      }
                                    >
                                      {subcategory.name}
                                      <div>
                                        <i
                                          className="mdi mdi-share"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            this.handleShare(subcategory.id);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          title="Share this category"
                                        />
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              ))
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                <div className="shop-head d-flex justify-content-between align-items-center">
                  <h5 className="mb-3">Category products</h5>

                  <Button
                    variant="contained"
                    className="download-btn"
                    onClick={this.handleDownloadPDF}
                  >
                    Download {currentcategoryname} Catalog
                  </Button>
                </div>

                {!isloaded ? (
                  <div className="progress-bar-bk">
                    <CircularProgress color="secondary" />
                    <div>Loading products...</div> {/* Loading message */}
                  </div>
                ) : toggle ? (
                  <div className="row no-gutters">
                    {currentProducts.length ? (
                      currentProducts.map((row, index) => (
                        <div key={index} className="col-md-4">
                          <div className="item">
                            <div
                              className="product"
                              style={{ backgroundColor: "black" }}
                            >
                              <Link
                                to={{
                                  pathname: `/p/${row.id}`,
                                  state: row,
                                }}
                              >
                                <div className="product-header">
                                  <img
                                    loading="lazy" // Lazy load images
                                    crossOrigin="anonymous"
                                    className="img-fluid"
                                    src={row.photo}
                                    alt={row.name}
                                    width="100%"
                                    height="auto"
                                    style={{ objectFit: "contain" }}
                                  />
                                </div>
                              </Link>
                              <div className="product-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm float-right"
                                  onClick={() =>
                                    this.handleProductShare(row.id)
                                  }
                                >
                                  <i className="mdi mdi-share" /> Share
                                </button>
                                <i
                                  className={`mdi ${
                                    wishlist.includes(row.id)
                                      ? "mdi-heart"
                                      : "mdi-heart-outline"
                                  } wishlist-icon`}
                                  onClick={() =>
                                    this.handleAddToWishlistClick(row.id)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    color: "gold",
                                  }} // Set color to gold for both
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-danger">
                        No products found in this category.
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="row no-gutters">
                    {list.length ? (
                      list.map((row, index) => (
                        <div key={index} className="col-md-4">
                          <div className="item">
                            <div
                              className="product"
                              style={{ backgroundColor: "black" }}
                            >
                              <Link
                                to={{
                                  pathname: `/p/${row.id}`,
                                  state: row,
                                }}
                              >
                                <div className="product-header">
                                  <img
                                    loading="lazy" // Lazy load images
                                    crossOrigin="anonymous"
                                    className="img-fluid"
                                    src={row.photo}
                                    alt={row.name}
                                    width="100%"
                                    height="auto"
                                    style={{ objectFit: "contain" }}
                                  />
                                </div>
                              </Link>
                              <div className="product-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm float-right"
                                  onClick={() =>
                                    this.handleProductShare(row.id)
                                  }
                                >
                                  <i className="mdi mdi-share" /> Share
                                </button>
                                <i
                                  className={`mdi ${
                                    wishlist.includes(row.id)
                                      ? "mdi-heart"
                                      : "mdi-heart-outline"
                                  } wishlist-icon`}
                                  onClick={() =>
                                    this.handleAddToWishlistClick(row.id)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    color: "gold",
                                  }} // Set color to gold for both
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-danger">No products found.</div>
                    )}
                  </div>
                )}
                <div className="pagination-controls">
                  <button
                    className="arrow-button"
                    onClick={this.handlePreviousPageGroup}
                    disabled={pageGroupStart === 1}
                  >
                    &lt;
                  </button>
                  {pageNumbers.map((number) => (
                    <button
                      key={number}
                      className={`page-button ${
                        currentPage === number ? "active" : ""
                      }`}
                      onClick={() => this.handlePageChange(number)}
                    >
                      {number}
                    </button>
                  ))}
                  <button
                    className="arrow-button"
                    onClick={this.handleNextPageGroup}
                    disabled={pageGroupStart + maxVisiblePages > totalPages}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (product) => dispatch(addToCart(product)),
});

export default connect(null, mapDispatchToProps)(Productview);
